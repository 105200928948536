
import React from 'react';
import GeneralContent from '../components/general-content';

import TermsContent from "../../content/terms-content.yaml"


export default function Terms() {
    let header = TermsContent.termsHeader
    let title = TermsContent.termsTitle
    let content = TermsContent.termsContent
    return (
        <GeneralContent header={header} title={title} content={content}></GeneralContent>

    )
}
